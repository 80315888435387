import { baseSessionPath } from './session';

export const rentalSessionPath = [...baseSessionPath, 'rental'];

export const rentalTicketPath = [...rentalSessionPath, 'ticket'];

/**
 *  Path that will hold the max return date and time for the rental
 *  @example gmi.session.rental.ticket.farthest_allowed_return_time
 */
export const rentalLastReturnDatePath = [...rentalTicketPath, 'farthest_allowed_return_time']; // session => rental => ticket => farthest_allowed_return_time

/**
 * Path that will hold the return location details returned from the session
 * @example gmi.session.rental.ticket.return_location
 */
export const rentalReturnLocationPath = [...rentalTicketPath, 'return_location']; // session => rental => ticket => return_location

/**
 * Path that will hold the pickup location details returned from the session
 * @example gmi.session.rental.ticket.pickup_location
 */

export const rentalPickupLocationPath = [...rentalTicketPath, 'pickup_location']; // session => rental => ticket => pickup_location

/**
 * Path that will hold the return date and time returned from the session
 * @example gmi.session.rental.ticket.return_time
 */
export const rentalReturnDateAndTime = [...rentalTicketPath, 'return_time']; // session => rental => ticket => return_time

/**
 * Path that will hold the pickup date and time returned from the session
 * @example gmi.session.rental.ticket.pickup_time
 */
export const rentalPickUpDateAndTime = [...rentalTicketPath, 'pickup_time']; // session => rental => ticket => pickup_time

/**
 * Path that will hold the Contract details returned from the session
 * @example gmi.session.rental.ticket.contract_details
 */
export const rentalContractDetailsPath = [...rentalTicketPath, 'contract_details']; // session => rental => ticket => contract_details
/**
 *
 * Path that will hold the payments returned from the session
 * @example gmi.session.rental.ticket.payments
 */
export const rentalPaymentsPath = [...rentalTicketPath, 'payments']; // session => rental => ticket => payments

// Rental Ticket Profile

export const rentalDriverInfoPath = [...rentalTicketPath, 'driver_info'];

export const rentalProfileDetailsPath = [...rentalTicketPath, 'profile_details'];

export const rentalProfileLoyaltyDataPath = [...rentalProfileDetailsPath, 'loyalty_data'];

export const rentalProfileLoyaltyNumberPath = [...rentalProfileLoyaltyDataPath, 'loyalty_number'];

export const rentalDriverFirstName = [...rentalDriverInfoPath, 'first_name'];

export const rentalDriverLastName = [...rentalDriverInfoPath, 'last_name'];

export const rentalDriverEmail = [...rentalDriverInfoPath, 'email_address'];

export const rentalDriverPhoneNumber = [...rentalDriverInfoPath, 'phone', 'phone_number'];

export const oneWayRentalPath = [...rentalTicketPath, 'one_way_rental'];

export const rentalCarClassDetailsPath = [...rentalTicketPath, 'car_class_details'];

export const prepayCursorPath = [...rentalTicketPath, 'prepay_selected'];

export const rentalMileageInfoPath = [...rentalCarClassDetailsPath, 'mileage_info'];

export const rentalBundlesPath = [...rentalTicketPath, 'bundles'];

export const rentalReturnConfirmedPath = [...rentalTicketPath, 'return_confirmed'];
export const rentalReturnOverduePath = [...rentalTicketPath, 'return_overdue'];

/* Session - GBO Flags */
export const virginReservationPathFlag = [...rentalTicketPath, 'virgin_reservation'];
export const expeditedReservationTypePathFlag = [...rentalTicketPath, 'expedited_reservation_type'];

export const rentalCouponsCursor = [...rentalTicketPath, 'coupons'];

export const rentalVehicleRatesPath = [...rentalTicketPath, 'car_class_details', 'vehicle_rates', 0];

export const rentalExtrasPath = [...rentalVehicleRatesPath, 'extras'];
export const rentalPriceSummaryPath = [...rentalVehicleRatesPath, 'price_summary'];
export const rentalPaymentLineItemsPath = [...rentalPriceSummaryPath, 'payment_line_items'];
export const rentalBillToSubTotals = [...rentalPriceSummaryPath, 'bill_to_subtotals'];
export const rentalTotalCurrency = [...rentalBillToSubTotals, 'total_currency'];
export const selectedPaymentMethodPath = [...rentalTicketPath, 'selected_payment_method'];
export const rentalIschargedAtReturnPath = [...rentalTicketPath, 'renter_charged_at_time_of_return'];
export const rentalIsInsurancePath = [...rentalTicketPath, 'is_insurance_replacement_flow'];

export const rentalTicketNumberPath = [...rentalTicketPath, 'ticket_number'];

/**
 * Path that will hold the flag to show french banner returned from the rental session
 * @example gmi.session.rental.show_view_fr_banner_commit
 */
export const showFRBannerCommitPath = [...rentalSessionPath, 'show_view_fr_banner_commit']; // session => rental => show_view_fr_banner_commit
