import { createSelector } from 'reselect';
import { List } from 'immutable';
import utils from 'utils';
import { utils as ehiUtils } from '@ehi/global-marketing-interface';
import GMI from 'constants/gmiConstants';
import {
  uiStateInitiatePath,
  uiStateShowPartnersLocations,
  uiPickupLocationTimeZone,
  uiOneWayRentalPath,
  uiInitiatePickupLocationPath,
  uiInitiateReturnLocationPath,
  uiStateFiltersPath,
  uiInitiatePickupTimePath,
  uiInitiateReturnTimePath,
  uiStatePassengersFilters,
  uiStateReservationCommitPath,
} from 'paths/uiState';
import { appVehiclesListPath, appVehicleCurrencyTogglePath } from 'paths/app';

const uiCommitStatePath = GMI.STATE.UI.RESERVATIONS.COMMIT;

export const getUiStatePhone = (state) => state.getIn([...uiCommitStatePath, 'driver_info', 'phone', 'phone_number']);

export const getUiStateFirstName = (state) => state.getIn([...uiCommitStatePath, 'driver_info', 'first_name']);

export const getUiStateLastName = (state) => state.getIn([...uiCommitStatePath, 'driver_info', 'last_name']);

export const getUiStateEmail = (state) => state.getIn([...uiCommitStatePath, 'driver_info', 'email_address']);

export const getUiStateInitiate = (state) => state.getIn(uiStateInitiatePath);

export const getUiInitiatePickupLocation = (state) => state.getIn(uiInitiatePickupLocationPath);

const getUiInitiateReturnLocation = (state) => state.getIn(uiInitiateReturnLocationPath);

export const getUiInitiatePickupTime = (state) => state.getIn(uiInitiatePickupTimePath);

export const getUiInitiateReturnTime = (state) => state.getIn(uiInitiateReturnTimePath);

export const getUiStateShowPartnersLocations = (state) => state.getIn(uiStateShowPartnersLocations, false);

export const getUiLocationTimezone = (state) => state.getIn(uiPickupLocationTimeZone);

export const getUiOneWayRentalFlag = (state) => state.getIn(uiOneWayRentalPath);

export const getFilterValues = (state, storeKey) => utils.safeToJS(state.getIn([...uiStateFiltersPath, storeKey]));

const getCurrentLocationsAvailability = (state) => utils.safeToJS(state.getIn(appVehiclesListPath));

export const getSelectedCurrencyToggle = (state) => state.getIn(appVehicleCurrencyTogglePath);

const getUiStatePassengersFilter = (state) => state.getIn(uiStatePassengersFilters);

export const getUiCommitStateSelector = (state) => utils.safeToJS(state.getIn(uiStateReservationCommitPath));

/**
 * Gets the request sms consent flag saved to app reservation state
 *
 * @param      {object}  state   The redux store state
 * @return     {boolean}  The request sms consent flag
 * @example
 * getAutoCommsConsentOptInSelector(state) => `auto_comms_consent_opt_in` value from `app.reservation.commit.auto_comms_consent_opt_in`
 */
export const getAutoCommsConsentOptInSelector = (state) =>
  state.getIn([...uiCommitStatePath, 'auto_comms_consent_opt_in']);

/**
 * GMI UI path for setting additional fields under reservation.commit state
 * @example 'gmi.ui.reservations.commit.additional_information'
 * @type {string[]}
 */
export const resCommitAdditionalInformationPath = [...uiCommitStatePath, 'additional_information'];

export const getUiInitiateState = createSelector([getUiStateInitiate], (initiate) => utils.safeToJS(initiate, {}));

export const uiInitiateLocationsSelector = createSelector(
  [getUiInitiatePickupLocation, getUiInitiateReturnLocation],
  (pickupLocation, returnLocation) => ({
    uiPickupLocation: utils.safeToJS(pickupLocation, {}),
    uiReturnLocation: utils.safeToJS(returnLocation, {}),
  })
);

export const currentFiltersSelector = createSelector(
  [getFilterValues],
  (currentFilter) => currentFilter?.filters || []
);

export const allFilterItemsSelector = createSelector(
  [getFilterValues],
  (currentFilter) => currentFilter?.items?.all || []
);

export const segmentedFilterItemsSelector = createSelector(
  [getFilterValues],
  (currentFilter) => currentFilter?.items?.segmented || []
);

export const filteredFilterItemsSelector = createSelector(
  [getFilterValues],
  (currentFilter) => currentFilter?.items?.filtered || []
);

export const sortedFilterItemsSelector = createSelector(
  [getFilterValues],
  (currentFilter) => currentFilter?.items?.filtered_sorted || []
);

export const segmentsIsActiveFilterSelector = createSelector(
  [getFilterValues],
  (currentFilter) => !!currentFilter?.segmentsIsActive
);

export const numFiltersSelectedFilterSelector = createSelector(
  [getFilterValues],
  (currentFilter) => currentFilter?.numFiltersSelected || 0
);

export const filterSortInfoSelector = createSelector(
  [getFilterValues],
  (currentFilter) => currentFilter?.sorting || null
);

export const getUiStatePassengersFilterSelector = createSelector([getUiStatePassengersFilter], (result) => result);
export const getUiStateNumberOfPassengersFiltersSelector = createSelector([getUiStatePassengersFilter], (result) => {
  let value = 0;
  if (result) {
    let passengersCheckboxesKeys = Object.keys(result);

    if (passengersCheckboxesKeys.length > 0) {
      passengersCheckboxesKeys = passengersCheckboxesKeys.filter((key) => result[key] === true);
      const keysLength = passengersCheckboxesKeys.length;

      if (keysLength) {
        value = keysLength - 1;
      }
    }
  }
  return value;
});

/**
 * getSortFilterValue - Check if should return filtered or sorted values based on 'no_sort_by' sortByKeyPath
 */
export const getSortFilterValue = (state, storeKey) =>
  filterSortInfoSelector(state, storeKey)?.sortByKeyPath === 'no_sort_by'
    ? filteredFilterItemsSelector(state, storeKey)
    : sortedFilterItemsSelector(state, storeKey);

// Location Type Filters
export const locationTypeFilterVehicleCodes = createSelector(
  [getFilterValues, getCurrentLocationsAvailability],
  (currentFilter, carClasses) => {
    const filterValues = carClasses;
    let currentFilters = currentFilter?.items?.filtered || [];

    const carClassAvailability = filterValues?.vehicles_availability?.car_class_availability;

    if (
      filterValues?.filters?.selectedVehicleCodes &&
      currentFilters?.length > 0 &&
      !!carClassAvailability &&
      Object.keys(filterValues?.filters?.selectedVehicleCodes)?.length > 0
    ) {
      currentFilters = currentFilters.map((location) => {
        const matchedLocatinId = carClassAvailability.find(
          (availability) => availability.pickup_location_id === location.id
        );
        const finalMatchedCodes = matchedLocatinId?.car_classes.filter(
          (carClass) =>
            filterValues?.filters?.selectedVehicleCodes &&
            filterValues?.filters?.selectedVehicleCodes.includes(carClass.code)
        );
        if (ehiUtils.isArrayNotEmpty(finalMatchedCodes)) {
          return {
            ...location,
            finalMatchedCodes: [...finalMatchedCodes],
          };
        }
        return null;
      });
    }
    return currentFilters?.filter((item) => item) || [];
  }
);

/**
 * Gets the additional information object from GMI UI commit state.
 * @param      {Object}  state   The redux state
 * @return     {Object[]}   Array of additional information fields objects
 * @see gmi.ui.reservations.commit.additional_information
 */
export const selectResCommitAdditionalInformation = createSelector(
  [(state) => state.getIn(resCommitAdditionalInformationPath, List([]))],
  (additionalInformation) => utils.safeToJS(additionalInformation)
);
