import { createSelector } from 'reselect';
import { gboProfilePath, gmaKeepMeLoggedInPath, gmaLoggedInPath } from 'paths/session';
import { gmaSubscriptionDetailsPath } from 'paths/gma';
import utils from 'utils';

const selectorContainer = {};
const getProfileData = (state) => state.getIn(gboProfilePath);
const getSubscriptionDetails = (state) => state.getIn(gmaSubscriptionDetailsPath);
const getLoggedIn = (state) => state.getIn(gmaLoggedInPath);
const getKeepMeLoggedIn = (state) => state.getIn(gmaKeepMeLoggedInPath);

/**
 * profileDataSelector - creates and returns a unique selector for any unique set of options.
 * This unusual selector construction is useful because when planning Alamo Insider component work,
 * most components had unique needs for which profile data to listen to. So this abstraction programmatically
 * generates an appropriate selector based on a component's needs instead of having to write a unique selector
 * for each component or using more generic selectors that pass down unnecessary data.
 *
 * The selectors only return the data specified by options, so options are required. It will return null if
 * profileData is not in state so a component has an easy way to tell when user not logged in (!profileData)
 *
 * There is currently no way to return the whole profileData object as this is functionality that we are unlikely to
 * need and should be discouraged if possible because converting a large chunk of immutable state to JS is expensive.
 * If we do encounter a scenario where this becomes necessary we can create an allData option, but it should be
 * noted that this should be used warily.
 *
 *
 * @param {object}          state - redux state
 * @param {object}          options - options object that determines which parts of profile state to return
 * @param {boolean}         options.age - if true, get the user profile age
 * @param {boolean}         options.first_name - if true, get the user's first_name
 * @param {boolean}         options.last_name - if true, get the user's last_name
 * @param {boolean}         options.address_profile - if true, get the user's address_profile
 * @param {boolean}         options.phones - if true, get the user's phones array
 * @param {boolean}         options.email - if true, get the user's email address
 * @param {boolean}         options.email_preference - if true, get the user's email preferences (subscriptions)
 * @param {boolean}         options.partnerRewardsProgram - if true, get the user profile default Partner Rewards Program
 * @param {boolean}         options.memberNumber - if true, get user memberNumber
 * @param {boolean}         options.user_name - if true, get user user_name
 * @param {boolean}         options.email - if true, get user email
 * @param {boolean}         options.special_offers - if true, returns whether or not user is enrolled in special offers emails
 * @param {boolean}         options.license_profile - if true, get user's license_profile
 * @param {boolean}         options.oci_eligible - if true, get user's oci_eligible
 * @param {boolean}         options.oci_individual_status - if true, get user's oci_individual_status
 * @param {boolean}         options.profile_completion - if true, get user's profile_completion as integer percentage
 * @return {(null|object)}  returns null if no profile in redux state, otherwise returns an object with requested options
 */
export const profileDataSelector = (state, options = {}) => {
  // optionsSignature creates a unique id based on the set of options passed, regardless of their order
  const optionsSignature = JSON.stringify(options, Object.keys(options).sort());

  // the following creates and stores a unique selector for each unique optionsSignature, then returns the appropriate
  // selector based on which set of a options a component is requesting
  if (!selectorContainer[optionsSignature]) {
    selectorContainer[optionsSignature] = createSelector([getProfileData], (profileData) => {
      if (!profileData || utils.gmi.isObjectEmpty(options)) {
        return null;
      }

      const returnData = {};
      if (options.age) {
        returnData.age = profileData.getIn(['profile', 'basic_profile', 'age']);
      }
      if (options.first_name) {
        returnData.first_name = profileData.getIn(['profile', 'basic_profile', 'first_name']);
      }
      if (options.last_name) {
        returnData.last_name = profileData.getIn(['profile', 'basic_profile', 'last_name']);
      }
      if (options.address_profile) {
        returnData.address_profile = utils.safeToJS(profileData.getIn(['address_profile']));
      }
      if (options.phones) {
        returnData.phones = utils.safeToJS(profileData.getIn(['contact_profile', 'phones']));
      }
      if (options.email) {
        returnData.email = profileData.getIn(['contact_profile', 'email']);
      }
      if (options.email_preference) {
        returnData.email_preference = utils.safeToJS(profileData.getIn(['preference', 'email_preference']), null);
      }
      if (options.partnerRewardsProgram) {
        const defaultPRPCode = profileData.getIn(['preference', 'reward_preferences', 'default_program_id']);
        const userPRPs = profileData.getIn(['preference', 'reward_preferences', 'partner_rewards_programs']);
        const prp = userPRPs?.find((program) => program.get('code') === defaultPRPCode);
        returnData.partnerRewardsProgram = utils.safeToJS(prp, null);
      }
      if (options.memberNumber) {
        returnData.memberNumber = profileData.getIn(['profile', 'basic_profile', 'loyalty_data', 'loyalty_number']);
      }
      if (options.memberProgram) {
        returnData.memberProgram = profileData.getIn(['profile', 'basic_profile', 'loyalty_data', 'loyalty_program']);
      }

      if (options.user_name) {
        returnData.user_name = profileData.getIn(['profile', 'user_name']);
      }
      if (options.special_offers) {
        returnData.special_offers = profileData.getIn(['preference', 'email_preference', 'special_offers']);
      }
      if (options.license_profile) {
        returnData.license_profile = utils.safeToJS(profileData.getIn(['license_profile']));
      }
      if (options.oci_eligible) {
        returnData.oci_eligible = profileData.getIn(['additional_data', 'oci_eligible']);
      }
      if (options.email_update_required) {
        returnData.email_update_required = profileData.getIn(['additional_data', 'email_update_required']);
      }
      if (options.oci_individual_status) {
        returnData.oci_individual_status = utils.safeToJS(
          profileData.getIn(['additional_data', 'oci_individual_status'])
        );
      }
      if (options.profile_completion) {
        returnData.profile_completion = parseInt(profileData.getIn(['additional_data', 'profile_completion']), 10);
      }

      return returnData;
    });
  }
  return selectorContainer[optionsSignature](state);
};

/**
 * authenticatedSelector - selector that returns a boolean for authenticated state based on existence of profileData.
 *
 * @return {boolean}  true if profileData exists indicating user is authenticated
 */
export const authenticatedSelector = createSelector([getProfileData], (profileData) => !!profileData);

export const subscriptionDetailsSelector = createSelector([getSubscriptionDetails], (subscriptionDetails) => ({
  country_of_residence: subscriptionDetails.get('country'),
  email: subscriptionDetails.get('email_address'),
  first_name: subscriptionDetails.get('first_name'),
  last_name: subscriptionDetails.get('last_name'),
}));

export const keepMeLoggedInSelector = createSelector([getKeepMeLoggedIn], (keepMeLoggedIn) => keepMeLoggedIn);

export const isLoggedInSelector = createSelector([getLoggedIn], (isLoggedIn) => isLoggedIn);
