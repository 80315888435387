/* eslint-disable no-underscore-dangle */
import { isObject, preferredOrder } from 'utils/object';
import { WINDOW_OBJECT_KEYS, ANALYTICS } from '../constants';

const analyticsKey = WINDOW_OBJECT_KEYS.ANALYTICS;
let analyticsInteractionQueue = []; // hold interactions that fired before initAnalytics was called
let analyticsDOMElement;

// String Utils
/**
 * This util strips all `_` out of a string and turns to lower case. This is the
 * pattern that the analytics object want for the keys (like the interaction key for example)
 * @param {object} key - a string
 */
export function analyticsPrepareKey(key) {
  return key ? key.toLowerCase()?.replace(/_/g, ' ') : '';
}

// Event
/**
 * This is a generic event that will be fired _any time_ that an action is fired in redux.
 * This function blindly passes the action along to this event emmitter. The intent is that
 * this is added to a redux middleware to make all actions available to analytics.
 * @param {object} action - a redux action
 */
export function reduxEvent(action) {
  document.dispatchEvent(new CustomEvent('reduxEvent', { detail: action }));
}

/**
 * Dispatch event
 * @param {HTMLElement} elem
 * @param event
 */
export function dispatchEvent(elem, event) {
  if (elem && event) {
    elem.dispatchEvent(event);
  }
}

/**
 * Dispatch event with DOMElement included
 * To test the event you can add the listener on the browser:
 * event = FlightAdded
 * document.querySelector('#analytics').addEventListener('analyticsFlightAdded', e => console.log(e) , false);
 * @param event
 */
export function dispatchEventWithDOM(event) {
  dispatchEvent(analyticsDOMElement, new CustomEvent(`analytics${event}`));
}

/**
 * This function strips all characters other then a-z, 0-9 and _ out of a string
 * replaceing spaces with `_` and converts everything to lowercase. This is intended
 * to be used to prepare `data-dtm-track` attribute values.
 *
 * @param {string} param - the string to clean
 *
 * @return {string} a clean/safe DTM data attribute string.
 */
export function cleanDtmParam(param = '') {
  if (!param) {
    return '';
  }
  return param
    .toLowerCase()
    .replace(/[^\w\s]/g, '')
    .replace(/\s+/g, '_');
}

// Methods
/**
 * Build string for data-dtm-track attribute
 * @param {String} object UI, buttons, links, carousels
 * @param {String} placement Context, branch hours, reservation flow
 * @param {String} description Action, next, prev
 * @param {String} others Additional info if needed
 */
export function dtm(object, placement, description, others) {
  return `${cleanDtmParam(object)}|${cleanDtmParam(placement)}|${cleanDtmParam(description)}${
    others ? `|${cleanDtmParam(others)}` : ''
  }`;
}

/**
 * This function builds the structure of `window._analytics` object
 * @param {Object} pageInfo - An object of AEM config from `window.ehi.aem.page_info`
 */
export const buildAnalyticsObject = () => {
  const pageInfo = window.ehi?.aem?.page_info;
  analyticsDOMElement = document.getElementById('analytics');
  // Set the base structure of `window._analytics`, if it is not already set!
  if (!window[analyticsKey]) {
    window[analyticsKey] = {
      ...pageInfo,
      interactions: [],
      errors: [],
      gma: null, // this will be an {}, set as null at start to indicate session has not loaded
      solr: {},
      applicationCodeVersions: window.ehi?.site?.applicationsCodeVersions,
    };
  }
};

/**
 * This function is used to save an analytics event to session storage. The idea is that these stashed events
 * will then be dispatched on the next page load.
 *
 * @param {String} eventType - This is the type of analaytic event being stashed (eg. `interaction`)
 * @param {Object} eventData - The properly shaped event object. The shape will vary depending upon the type
 *                             of event that is being stashed. See `interaction` as for examples.
 *
 * Note: As of the time this was written only `interactions` are supported. If errors are later needed
 * we will need to extend the `analyticsInteractionQueue` idea as well as the hydration of that array
 * in the `initAnalytics` method.
 *
 * @return {void}
 */
export const stashAnalyticsEvent = (eventType, eventData) => {
  // Get current analytics session storage data.
  const sessionData = JSON.parse(sessionStorage.getItem(ANALYTICS.SESSION_STORAGE_KEY)) || {};

  // The event's shoudl be stashed in the `eventType` arrays (like `interaction` or perhaps `error`). So
  // first we need to check if the array exists and push an index onto the end or create the array with
  // the first index being this interaction.
  if (sessionData[eventType]) {
    sessionData[eventType].push(eventData);
  } else {
    sessionData[eventType] = [eventData];
  }

  // Push the new value to session storage
  sessionStorage.setItem(ANALYTICS.SESSION_STORAGE_KEY, JSON.stringify(sessionData));
};

/**
 * Interactions handling. If analytics has already initiated, this will dispatch an interaction
 * event. If the init has _not_ yet happened, this enqueues the events and then dispatchest them
 * all at once later on init.
 * @param {String} type
 * @param {String} key
 * @param {String} value
 * @param {Object} data - additional data to optionally pass into the interaction object
 * @param {Boolean} stashInteraction - This flag indicates if interaction should be saved (stashed)
 *                                     for dispatch on the next page load. The main uses case of this
 *                                     is for interactions that are fired right as the page is
 *                                     redirected.
 */
export const analyticsInteraction = (type, key, value, data, stashInteraction) => {
  const interaction = { type, key, value, ...(!!data && { data }) };

  // Make sure the window object is already set up!
  if (!window[analyticsKey]) {
    buildAnalyticsObject();
  }

  // If the `stashInteraction` flag is set then regardless of anything else we will save the interaction in
  // session storage for later dispatch.
  if (stashInteraction) {
    stashAnalyticsEvent('interactions', interaction);
  }
  // If the analytics object contains the gma data, this means that the session response has completed
  // and thus the analytics ready event should have already been dispatched. Otherwise we are not ready
  // to fire interactions off as `_analytics` is not fully built/ready.
  else if (window[analyticsKey] && window[analyticsKey].gma) {
    // Pass type, key and value to the interaction as well as an _optional_ data object
    window[analyticsKey].interactions.push(interaction);

    if (!analyticsDOMElement) {
      analyticsDOMElement = document.getElementById('analytics');
    }
    // Set index position as attribute to analytics dom element to be captured by dashboard
    analyticsDOMElement.setAttribute('indexPosition', window[analyticsKey].interactions.length - 1);

    // Send event with index of array position
    dispatchEvent(
      analyticsDOMElement,
      new CustomEvent('analyticsInteraction', { indexPosition: window[analyticsKey].interactions.length - 1 })
    );
  } else {
    // if there is no analytics object yet, queue the interaction to be fired when analytics is ready
    analyticsInteractionQueue.push(interaction);
  }
};

/**
 * This function is used to take any values that are stashed in local storage and "rehydrate" the
 * analytics "queue(s)". This is intended to be used _before_ the analytics ready event is fired.
 */
const hydrateAnalyticsFromStash = () => {
  const sessionData = JSON.parse(sessionStorage.getItem(ANALYTICS.SESSION_STORAGE_KEY)) || null;

  // If there were any stashed values hydrate the proper queues and then clear out session storage
  if (sessionData) {
    // clear out the session
    sessionStorage.removeItem(ANALYTICS.SESSION_STORAGE_KEY);

    // rehydrate the interactions queue
    if (sessionData.interactions) {
      analyticsInteractionQueue = analyticsInteractionQueue.concat(sessionData.interactions);
    }
  }
};

/**
 * Function to push to `window.dataLayer` and `window.adobeDataLayer` an event called `page_load`
 * with the analytics object on it.
 *
 * @private
 * @param {Object} analyticsData Object containing the analytics data
 */
const updateDataLayers = (analyticsData) => {
  if (isObject(analyticsData)) {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.dataLayer = window.dataLayer || [];

    window.adobeDataLayer.push({ event: 'page_load', ...analyticsData });
    window.dataLayer.push({ event: 'page_load', ...analyticsData });
  }
};

/**
 * Function to push to `window.application_code_versions` and `window.application_code_versions` an event called `gma_region`
 *
 * @private
 * @param {Object} analyticsData Object containing the analytics data
 */
const addRegion = (analyticsData) => {
  if (isObject(analyticsData)) {
    // using gmaUrl to determine the region which services is currently being used
    let gmaRegion = window.ehi?.site?.gmaUrl;
    if (gmaRegion) {
      gmaRegion = gmaRegion?.replace(/.+\/\/|www.|\..+/g, '');
      if (isObject(analyticsData)) {
        analyticsData.application_code_versions = {
          ...analyticsData.application_code_versions,
          gma_region: gmaRegion,
        };
      }
    }
  }
};

/**
 * This funciton is called to dispatch the actual analytics ready event and
 * make any needed updates to the `_analytics` object before doing so.
 */
export const initAnalytics = () => {
  // Pull any stashed analytics values (from previous page) into Analytics Queue array.
  hydrateAnalyticsFromStash();

  // Make sure the window object is already set up!
  if (!window[analyticsKey]) {
    buildAnalyticsObject();
  }

  // Dispatch the Analytics ready event!
  dispatchEvent(analyticsDOMElement, new CustomEvent('analytics'));

  // Update dataLayer and adobeDataLayer with the analytics data
  updateDataLayers(window[analyticsKey]);

  // If interactions were dispatched _before_ `initAnalytics` was called, they all got
  // enqueued. This checks for queued interactions, loops through and dispatch each one.
  if (analyticsInteractionQueue.length > 0) {
    analyticsInteractionQueue.forEach((interaction) => {
      analyticsInteraction(interaction.type, interaction.key, interaction.value, interaction.data);
    });
    analyticsInteractionQueue = [];
  }
};

/**
 * This method clears the analytics `errors` array.
 */
export const analyticsClearErrors = () => {
  if (window[analyticsKey]) {
    window[analyticsKey].errors = [];
  }
};

/**
 * This method fires a custom analytics event.
 */
export const analyticsDispatchErrorEvent = () => {
  dispatchEvent(analyticsDOMElement, new CustomEvent('analyticsError'));
};

/**
 * This method adds an object to the analytics `errors` object and then dispatches the custom event. The analytics team expects
 * that a customer event will always be dispatched when new error is pushed.
 * @param {String} url
 * @param {String} key
 * @param {String} value
 */
export const analyticsError = (url, key, value, data) => {
  if (window[analyticsKey]) {
    const { errors } = window[analyticsKey];
    errors.push({ url, key, value });
    // AWR-8647 - Per QA, the custom events are slashed to a single custom event regardless of the number of errors
    // In Form.js calling Function 'analyticsDispatchErrorEvent' to combine them in one custom event
    // https://confluence.ehi.com/display/MA/Error+Handling+Update
    if (!data?.skipCustomEvent) {
      dispatchEvent(analyticsDOMElement, new CustomEvent('analyticsError'));
    }
  }
};

/**
 * This method is for adding paymentSessionId on analytics `payment_session_id` object.
 * @param {String} paymentSessionId
 */
export const analyticsPaymentSession = (paymentSessionId) => {
  const analyticsObj = window[analyticsKey];
  if (analyticsObj) {
    analyticsObj.payment_session_id = paymentSessionId;
  }
};

/**
 * Merges GMA and AEM analytics objects and
 * assigns to analytics obj & dispatches analytics event
 * @param {Object} gmaResponse
 */
export function mergeGmaIntoAnalytics(gmaResponse = {}) {
  // Make sure that the structure of the analytics object has been set before we merge in GMA data
  if (!window[analyticsKey]) {
    buildAnalyticsObject();
  }

  // if we just called buildAnalyticsObject, then window[analyticsKey].gma === null before we merge in the gmaResponse
  // so we set shouldInit to initiate the analytics object this one time
  const shouldInit = !window[analyticsKey]?.gma;

  // sorting gbo analyticsData object if it contains reservation_sub_total.currencies,
  // to address analytics issue for switching wrong currencies for analytics

  if (gmaResponse?.gbo?.reservation?.analytic_data?.reservation_sub_total?.currencies) {
    const currenciesObj = gmaResponse.gbo.reservation.analytic_data.reservation_sub_total.currencies;
    const updatedCurrence = preferredOrder(currenciesObj, ['GBP', 'USD', 'EUR', 'CAD']);
    gmaResponse.gbo.reservation.analytic_data.reservation_sub_total.currencies = updatedCurrence;
  }

  // Making sure gma.modify is true when rental.modify is true
  if (gmaResponse?.rental?.modify === true && gmaResponse?.gma?.modify === 'false') {
    gmaResponse.gma.modify = 'true';
  }

  // Merge the gma Response object into the `_analytics` object!
  Object.assign(window[analyticsKey], gmaResponse);

  // Dispatch the custom analytics GMA event
  dispatchEvent(analyticsDOMElement, new CustomEvent('analyticsSessionResponse'));

  // Add Service Region to analytics data layer
  addRegion(window[analyticsKey]);

  // We dispatch the actual init event here because GMA session is called on _every_ page load.
  // This is a conditional dispatch though as session is also updated for things like adding an extra...
  if (shouldInit) {
    initAnalytics();
  }
}

/**
 * Merges Solr response and AEM analytics objects and
 * assigns to analytics obj & dispatches analytics event
 * @param {Object} solrResponse
 */
export function mergeSolrIntoAnalytics(solrResponse = {}) {
  // Make sure that the structure of the analytics object has been set before we merge in Solr data
  if (!window[analyticsKey]) {
    buildAnalyticsObject();
  }

  // Merge in the data
  Object.assign(window[analyticsKey].solr, solrResponse);

  // Dispatch the Solr Response event
  dispatchEvent(analyticsDOMElement, new CustomEvent('analyticsSolrResponse'));
}

/**
 * Merges Solr response and AEM analytics objects and
 * assigns to analytics obj & DO NOT dispatch an analytics event https://jira.ehi.com/browse/AWR-4662
 * @param {Object} solrResponse
 */
export function mergeSolrLocationInfoIntoAnalytics(solrResponse = {}) {
  // Make sure that the structure of the analytics object has been set before we merge in Solr data
  if (!window[analyticsKey]) {
    buildAnalyticsObject();
  }

  // Merge in the data
  Object.assign(window[analyticsKey].solr, solrResponse);
}

/**
 * Return correct analytics constant type
 * @param {String} key
 */
export const determineType = (key) => {
  let type;

  switch (key) {
    case 'car_classes':
    case 'vehicle-page__grid':
      type = ANALYTICS.VEHICLE;
      break;
    case 'location_finder':
      type = ANALYTICS.LOCATION;
      break;
    default:
      type = ANALYTICS.DEFAULT;
      break;
  }

  return type;
};

/**
 * This function adds reservation info into _analytics object
 * @param {boolean} isSoldOutArea - if the area is sold out or not
 */
export const addSoldOutAreaInfo = (isSoldOutArea) => {
  if (window[analyticsKey]) {
    window[analyticsKey].reservation = {
      soldOutArea: isSoldOutArea,
    };
  }
  // Dispatch custom sold out area analytics event
  dispatchEvent(analyticsDOMElement, new CustomEvent('analyticsSoldOutArea'));
};

/**
 * This function adds reCaptcha info into _analytics object
 * @param {boolean} isSuccess - if the reCaptcha is success or not
 */
export const addReCaptchaInfo = (isSuccess) => {
  if (window[analyticsKey]) {
    window[analyticsKey].reCaptcha = {
      success: isSuccess,
    };
  }
  // Dispatch custom sold out area analytics event
  dispatchEvent(analyticsDOMElement, new CustomEvent('analyticsReCaptchaInfo'));
};

/**
 * This function replaces key with FE_PHONE info if key includes string phone
 * @param {string} key - if the key contains phone or not
 */

export const analyticsFormKey = (key) => (key.includes(ANALYTICS.PHONE) ? ANALYTICS.PHONE : key);
