import { createSelector } from 'reselect';
import { safeToJS } from 'utils/immutable';
import { gboPartnerRewardProgramPath } from 'paths/session';

/** GMI * */
// Returns the available Partner Rewards Programs by country
export const getAvailablePartnerRewardsPrograms = state =>
  safeToJS(state.getIn(['gmi', 'gma', 'reference', 'partner_rewards']), {});

// Returns the existing content for PRP Surcharge
export const getSurchargeContent = state => state.getIn(['gmi', 'gma', 'content', 'partner_reward_programs_surcharge']);

/** GBO * */
// Returns an object if the reservation has partner_reward_program
const getReservationPartnerRewardProgram = state => state.getIn(gboPartnerRewardProgramPath);
export const reservationPartnerRewardsProgramSelector = createSelector(
  [getReservationPartnerRewardProgram],
  partnerRewardProgram =>
    partnerRewardProgram
      ? {
          code: partnerRewardProgram.get('code'),
          memberId: partnerRewardProgram.get('member_id'),
          programName: partnerRewardProgram.get('program_name'),
        }
      : null
);
