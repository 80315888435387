import { createSelector } from 'reselect';
import { safeToJS } from 'utils/immutable';
import { isSafeNumberVal } from 'utils/number';
import { i18n } from 'utils/i18n';
import CUSTOM_PATH from 'constants/customPathCheckIn';
import RESERVATIONS from 'constants/reservations';
import POLICIES from 'constants/policies';

import {
  rentalLastReturnDatePath,
  rentalReturnLocationPath,
  rentalPickupLocationPath,
  rentalReturnDateAndTime,
  rentalPickUpDateAndTime,
  rentalDriverFirstName,
  rentalDriverLastName,
  rentalDriverEmail,
  rentalDriverPhoneNumber,
  oneWayRentalPath,
  virginReservationPathFlag,
  expeditedReservationTypePathFlag,
  rentalCouponsCursor,
  rentalTicketPath,
  prepayCursorPath,
  rentalVehicleRatesPath,
  rentalExtrasPath,
  rentalPriceSummaryPath,
  rentalTotalCurrency,
  rentalBundlesPath,
  rentalReturnOverduePath,
  rentalReturnConfirmedPath,
  rentalContractDetailsPath,
  rentalPaymentsPath,
  rentalTicketNumberPath,
  rentalIschargedAtReturnPath,
  rentalMileageInfoPath,
  rentalPaymentLineItemsPath,
  rentalIsInsurancePath,
  showFRBannerCommitPath,
} from 'paths/rentalTicket';

/* Constants */
const { CUSTOM_PATH_RESERVATION_TYPE } = CUSTOM_PATH;
const {
  ADDITIONAL_DRIVER_EXTRAS_CODE,
  ADDITIONAL_DRIVER_INCLUDED_STATUS,
  EXTRAS_INCLUDED_STATUS,
  EXTRAS_MANDATORY_STATUS,
  EXTRAS_OPTIONAL_STATUS,
  EXTRAS_WAIVED_STATUS,
} = RESERVATIONS;

const keyFactsPoliciesCursor = [...rentalTicketPath, 'key_facts_policies'];

const getKeyFactsPolicies = (state) => state.getIn(keyFactsPoliciesCursor);

export const additionalDriversPolicySelector = createSelector([getKeyFactsPolicies], (policies) =>
  (safeToJS(policies) || []).find(({ code }) => code === POLICIES.ADDITIONAL_DRIVERS)
);

export const getRentalLastReturnDate = (state) => state.getIn(rentalLastReturnDatePath);

export const selectedPaymentMethodPath = [...rentalTicketPath, 'selected_payment_method'];

export const selectedPaymentTypePath = [...selectedPaymentMethodPath, 'payment_type'];

export const getRentalReturnOverdue = (state) => state.getIn(rentalReturnOverduePath);

export const getRentalReturnConfirmed = (state) => state.getIn(rentalReturnConfirmedPath);

export const getShowFRBannerCommit = (state) => state.getIn(showFRBannerCommitPath);

/**
 * Get the return date and time returned from the session
 * @param {Object} - redux state
 * @returns {Object} - The return date and time returned from the session
 */
export const getReturnDateAndTime = (state) => state.getIn(rentalReturnDateAndTime, {});

/**
 * Get the location details returned from the session
 *
 * @param {Object} - redux state
 * @returns {Object} - The location details returned from the session
 */

// export const getRentalReturnLocation = (state) => state.getIn(rentalReturnLocationPath, Map({}));
export const getRentalReturnLocation = (state) => safeToJS(state.getIn(rentalReturnLocationPath, {}));

export const getRentalPaymentLineItems = (state) => safeToJS(state.getIn(rentalPaymentLineItemsPath));

// export const getRentalReturnLocation = (state) => state.getIn(rentalPickupLocationPath, Map({}));
export const getRentalPickupLocation = (state) => safeToJS(state.getIn(rentalPickupLocationPath, {}));

/**
 * Get the pickup date and time returned from the session
 * @param {Object} - redux state
 * @returns {Object} - The pickup date and time returned from the session
 */
export const getPickUpDateAndTime = (state) => state.getIn(rentalPickUpDateAndTime, {});

export const getContractDetails = (state) => safeToJS(state.getIn(rentalContractDetailsPath));

// export const getPayments = (state) => safeToJS(state.getIn(rentalPaymentsPath));

/**
 * Returns location details for the return location
 *
 * @param {object} state - redux state
 * @returns {boolean}
 */
export const selectReturnLocationDetails = createSelector(getRentalReturnLocation, (returnLocationDetails) => ({
  locationName: returnLocationDetails?.name,
  streetAddress: returnLocationDetails?.address?.street_addresses[0],
  locationCity: returnLocationDetails?.address?.city,
  countrySubdivisionCode: returnLocationDetails?.address?.country_subdivision_code,
  postalCode: returnLocationDetails?.address?.postal,
  phoneNumber: returnLocationDetails?.phones?.[0].formatted_phone_number,
  id: returnLocationDetails?.id,
}));

export const selectPickupLocationDetails = createSelector(getRentalPickupLocation, (pickupLocationDetails) => ({
  locationName: pickupLocationDetails?.name,
  streetAddress: pickupLocationDetails?.address?.street_addresses[0],
  locationCity: pickupLocationDetails?.address?.city,
  countrySubdivisionCode: pickupLocationDetails?.address?.country_subdivision_code,
  postalCode: pickupLocationDetails?.address?.postal,
  phoneNumber: pickupLocationDetails?.phones?.[0].formatted_phone_number,
  id: pickupLocationDetails?.id,
}));

export const getRentalInfoFirstName = (state) => state.getIn(rentalDriverFirstName);

export const getRentalInfoLastName = (state) => state.getIn(rentalDriverLastName);

export const getRentalInfoEmail = (state) => state.getIn(rentalDriverEmail);

export const getRentalInfoPhoneNumber = (state) => state.getIn(rentalDriverPhoneNumber);

export const getOneWayRentalFlag = (state) => state.getIn(oneWayRentalPath);

const getRentalCoupons = (state) => state.getIn(rentalCouponsCursor);

const getVirginReservation = (state) => state.getIn(virginReservationPathFlag);
const getExpeditedFlag = (state) => state.getIn(expeditedReservationTypePathFlag);

const getGuaranteedVehicleFlag = (state) =>
  state.getIn([...rentalTicketPath, 'car_class_details', 'guaranteed_vehicle']);

const getGuaranteedVehicleRequiresCardFlag = (state) =>
  state.getIn([...rentalTicketPath, 'car_class_details', 'guaranteed_res_credit_card_required']);

export const getLocationInfo = (state) => safeToJS(state.getIn(['gmi', 'gma', 'location', 'by_id'], {}));

export const getDidPrepay = (state) => state.getIn(prepayCursorPath);

export const getRentalPayments = (state) => state.getIn(rentalPaymentsPath);

const getVehicleRates = (state) => state.getIn(rentalVehicleRatesPath);

const getBundles = (state) => state.getIn(rentalBundlesPath);

export const getRentalExtras = (state) => state.getIn(rentalExtrasPath);

export const getRentalTotalCurrency = (state) => state.getIn(rentalTotalCurrency);

/* ---- Create Selectors ---- */

export const getLocationInfoByIdSelector = createSelector(
  [getLocationInfo, getOneWayRentalFlag, getRentalReturnLocation, getRentalPickupLocation],
  (locationInfo, isOneWay, returnLocation, pickupLocation) => {
    const id = isOneWay ? returnLocation?.id : pickupLocation?.id;
    return locationInfo?.[id]?.location;
  }
);

// Return boolean - true if reservation is Virgin
export const isVirginReservationSelector = createSelector(
  [getVirginReservation, getExpeditedFlag],
  (isVirginReservation, reservationType) =>
    isVirginReservation || (reservationType && reservationType === CUSTOM_PATH_RESERVATION_TYPE.BRAND_VIRGIN)
);

// we have various needs to peek inside of the selected vehicle rate
// this eliminates the need to repeatedly code to check which rate type is applied
export const vehicleRateSelector = createSelector([getVehicleRates], (vehicleRates) => vehicleRates);

// Returns true if reservation is Restricted Non-Virgin Reservation
export const isRestrictedReservationSelector = createSelector(
  [getExpeditedFlag],
  (reservationType) => reservationType && reservationType === CUSTOM_PATH_RESERVATION_TYPE.BRAND_RESTRICTED
);

export const isCustomPathReservationSelector = createSelector(
  [isRestrictedReservationSelector, isVirginReservationSelector],
  (isRestricted, isVirginReservation) => !!(isRestricted || isVirginReservation)
);

export const rentalCouponsSelector = createSelector([getRentalCoupons], (coupons) => safeToJS(coupons, []));

export const guaranteedResCardRequiredSelector = createSelector(
  [getGuaranteedVehicleFlag, getGuaranteedVehicleRequiresCardFlag],
  (guaranteedVehicle, cardRequired) => guaranteedVehicle && cardRequired
);

// selectors
export const rentalFormattedPoliciesSelector = createSelector([getLocationInfoByIdSelector], (locationInfo) => {
  const policyObjectRaw = locationInfo?.policies;
  if (!policyObjectRaw) {
    return [];
  }
  return policyObjectRaw?.map((policy) => ({
    name: policy.description,
    content: policy.policy_text,
    code: policy.code,
  }));
});

export const creditPaymentObjectSelector = createSelector([getRentalPayments], (paymentsObject) =>
  safeToJS(paymentsObject?.find((paymentObj) => paymentObj.get('transaction_type') === 'CREDIT'))
);

// Extras Selectors
// assign numerical values to known statuses for sorting
// lesser values will appear earlier in the list
const STATUS_SORT_VALUES = {
  [EXTRAS_INCLUDED_STATUS]: 0,
  [ADDITIONAL_DRIVER_INCLUDED_STATUS]: 1,
  [EXTRAS_MANDATORY_STATUS]: 2,
  [EXTRAS_WAIVED_STATUS]: 3,
  [EXTRAS_OPTIONAL_STATUS]: 4,
};

export const sortByStatus = (a, b) => STATUS_SORT_VALUES[a.status] - STATUS_SORT_VALUES[b.status];

export const selectedBundleNotDefaultSelector = createSelector([getBundles], (bundles) => {
  const selected = bundles?.find((item) => item.get('selected') && !item.get('default_bundle'));
  return safeToJS(selected, {});
});

// Auxiliary function to be used when reducing an extras array to get total of selected extras. Counts
// additional driver extras
const getSumOfSelectedQuantities = (acc, curr) =>
  curr.code !== ADDITIONAL_DRIVER_EXTRAS_CODE ? acc + isSafeNumberVal(curr.selected_quantity) : acc + 1;

// Gets the number of additional drivers added from a bundle selection
export const bundledAdditionalDriversSelector = createSelector(
  [selectedBundleNotDefaultSelector],
  (selectedBundle) =>
    selectedBundle?.vehicle_rates?.supplemental?.additional_driver?.number_of_free_additional_drivers || null
);

/*
 * Derives includedAdditionalDrivers object with policy as detailed_description and other necessary properties for
 * Resflow consumption (ProgressBar, Confirmation, Extras FlyoutTabs)
 */
const includedAdditionalDriversSelector = createSelector(
  [vehicleRateSelector, bundledAdditionalDriversSelector, additionalDriversPolicySelector],
  (vehicleRate, bundledAdditionalDrivers, additionalDriversPolicy) => {
    if (!vehicleRate || bundledAdditionalDrivers) {
      // if bundleAdditionalDrivers exists, additional drivers are from bundle and override any included drivers
      // so included driver object is set to null. vehicleRate check here just returns null if the reservation data isn't loaded yet
      return null;
    }
    const additionalDrivers = vehicleRate?.getIn(['supplemental', 'additional_driver']);
    const status = additionalDrivers?.get('status');
    const numberOfDrivers = additionalDrivers?.get('number_of_free_additional_drivers');
    if (status === ADDITIONAL_DRIVER_INCLUDED_STATUS && numberOfDrivers > 0) {
      return {
        name: i18n('included_additional_drivers_extra_name'),
        status,
        selected_quantity: numberOfDrivers,
        showCount: true, // currently controls whether to show count (selected_quantity) on Extras FlyoutTab
        code: additionalDrivers?.get('code'),
        detailed_description: additionalDriversPolicy?.policy_text,
      };
    }
    return null;
  }
);

// Returns an object with { [bundle name]: [list of extras] } only for bundles that have included extras which are NOT also included as part of default bundle
export const includedNotDefaultBundleExtrasSelector = createSelector([getBundles], (bundles) => {
  const includedBundleExtras = {};

  safeToJS(bundles, []).forEach(({ name, vehicle_rates }) => {
    const extras = vehicle_rates?.extras || {};
    const includedExtras = [];
    const keys = Object.keys(extras);

    if (keys.length) {
      keys.forEach((key) => {
        const included = extras[key].filter(
          (extra) =>
            (extra.status === EXTRAS_INCLUDED_STATUS || extra.status === EXTRAS_MANDATORY_STATUS) &&
            !extra.default_bundle_inclusion
        );

        includedExtras.push(...included);
      });
      includedBundleExtras[name] = includedExtras;
    }
  });

  return includedBundleExtras;
});

export const selectedBundleWithExtrasSelector = createSelector(
  [includedNotDefaultBundleExtrasSelector, selectedBundleNotDefaultSelector],
  (includedBundleExtras, selectedBundle) =>
    selectedBundle?.name
      ? {
          ...selectedBundle,
          bundleExtras: includedBundleExtras[selectedBundle.name],
        }
      : null
);

// pulls the extras object from the correct vehicle rate type [paynow, paylater]
export const extrasSelector = createSelector([vehicleRateSelector], (vehicleRate) =>
  safeToJS(vehicleRate?.get('extras'), {})
);

// grabs extras that are selected in any way, to be show on the review page
export const selectedExtras = createSelector(
  [extrasSelector, includedAdditionalDriversSelector],
  (extras, includedAdditionalDrivers) => {
    const { equipment = [], insurance = [], fuel = [], miscellaneous = [] } = extras;

    return [...insurance, ...equipment, ...fuel, ...miscellaneous, includedAdditionalDrivers]
      .filter((extra) => !!extra?.selected_quantity)
      .sort(sortByStatus);
  }
);

export const extrasWithoutBundlesSelector = createSelector(
  [selectedExtras, selectedBundleWithExtrasSelector],
  (extras, bundleWithExtras) => {
    const bundlesExtras = bundleWithExtras?.bundleExtras || [];
    return (
      extras?.filter((extra) => {
        const inBundle = bundlesExtras.some((ext) => ext.code === extra.code);
        return !inBundle || (inBundle && extra.selected_quantity > 0 && extra.status === EXTRAS_OPTIONAL_STATUS);
      }) || []
    );
  }
);

export const includedOrAddedExtrasSelector = createSelector([extrasWithoutBundlesSelector], (extras) => {
  const includedExtras = [];
  const addedExtras = [];

  extras.forEach((extra) => {
    if (extra.status === EXTRAS_INCLUDED_STATUS) {
      includedExtras.push(extra);
    } else if (
      extra.status === EXTRAS_OPTIONAL_STATUS ||
      extra.status === EXTRAS_MANDATORY_STATUS ||
      extra.status === EXTRAS_WAIVED_STATUS
    ) {
      addedExtras.push(extra);
    }
  });

  return { includedExtras, addedExtras };
});

// Specific selector to get added extras + bundle total amounts; see `numberOfExtrasSelector` for more info
export const numberOfAddedExtrasSelector = createSelector(
  [includedOrAddedExtrasSelector, selectedBundleNotDefaultSelector],
  ({ addedExtras }, bundleSelected) => {
    const addedExtrasAmount = addedExtras?.reduce(getSumOfSelectedQuantities, 0);
    const bundleAmount = bundleSelected?.name ? 1 : 0;
    return addedExtrasAmount + bundleAmount;
  }
);

// adds includedAdditionalDrivers object to the includedExtras array
export const includedExtrasAndDriversSelector = createSelector(
  [includedOrAddedExtrasSelector, includedAdditionalDriversSelector],
  ({ includedExtras }, includedAdditionalDrivers) => [
    ...includedExtras,
    ...(includedAdditionalDrivers ? [includedAdditionalDrivers] : []),
  ]
);

/*
  We should count multiples of something per item, but additional drivers and the bundle should be one item,
  thus calculation of extras is broken down like so:
    - Single Extra = 1
    - Extra w multiple (x3) = 3
    - Additional Driver (x3) = 1
    - Bundle = 1
    - Extras in a bundle = 0
*/
export const numberOfExtrasSelector = createSelector(
  [includedExtrasAndDriversSelector, numberOfAddedExtrasSelector],
  (includedExtrasAndDrivers, addedExtrasAmount) => {
    const includedExtrasAmount = includedExtrasAndDrivers?.reduce(getSumOfSelectedQuantities, 0);
    return includedExtrasAmount + addedExtrasAmount;
  }
);

export const getRentalMileageInfo = (state) => safeToJS(state.getIn(rentalMileageInfoPath));

export const selectedPaymentMethodSelector = createSelector([getRentalPayments], (paymentsObject) => {
  const payments = safeToJS(paymentsObject);
  const lastPaymentDetails = payments?.[0]?.card_details;
  return lastPaymentDetails
    ? {
        number: lastPaymentDetails?.last_four_digits,
        card_type: lastPaymentDetails?.card_type,
        payment_id: lastPaymentDetails?.last_four_digits,
        payment_type: lastPaymentDetails?.payment_type,
      }
    : null;
});

export const getRentalPriceSummary = (state) => safeToJS(state.getIn(rentalPriceSummaryPath), {});

export const getTicketNumber = (state) => state.getIn(rentalTicketNumberPath);

export const getRentalIschargedAtReturn = (state) => state.getIn(rentalIschargedAtReturnPath);

export const getRentalIsInsurance = (state) => state.getIn(rentalIsInsurancePath);

/** Returns the modification breakdown lines in the format of label and amount
 *
 * @param {object} amounts - object with amount values
 * @param {object[]} amounts.billToAccounts - array of corporate payment lines
 * @param {object} amounts.subTotalSummary - an object containing the subtotal of the section
 * @param {boolean} amounts.rentalIsInsurance - check whether user is on insurance flow
 *
 * @returns {object}
 */
export const getAdjustmentsSectionBreakdown = ({ billToAccounts, subtotalViewAmount }, contractDetails) => {
  const billToAccountBreakdown = billToAccounts?.map((company) => ({
    label: `${contractDetails?.contract_name} ${i18n('rental_details_estimated_total')}`,
    amount: company?.total_currency,
    isNegative: +(company?.total_currency?.amount ?? 0) !== 0,
  }));

  const estimatedTotalLabel = i18n('your_new_estimated_total_adjustment');

  return {
    sectionTitle: i18n('rental_details_taxes_fees_adjustments'),
    sectionItems: [...billToAccountBreakdown, { label: estimatedTotalLabel, bold: true, amount: subtotalViewAmount }],
  };
};

/** Returns the modification breakdown lines in the format of label and amount
 *
 * @param {object} priceSummary - array of corporate payment lines
 * @param {boolean} showNegativeDifference - should negative difference values be shown?
 *
 * @returns {object} -
 */
export const getModificationRentalBreakdown = (priceSummary, showCorporateCoverage) => {
  const rentalCostBeforeChanges = {
    label: i18n('estimate_rental_total_before_modification'),
    amount: priceSummary?.total_rental_cost_before_changes,
  };

  const rentalModification = {
    label: i18n('rental_modification'),
    amount: priceSummary?.difference_in_rental_cost_from_retrieve_ticket_with_negative,
  };

  const estimatedRentalTotal = {
    label: i18n(showCorporateCoverage ? 'your_new_estimated_total_modification' : 'your_new_estimated_total'),
    bold: true,
    amount: priceSummary?.rental_total_view,
  };

  return {
    sectionTitle: 'Modified Rental',
    sectionItems: [rentalCostBeforeChanges, rentalModification, estimatedRentalTotal],
  };
};

export const getShowCorporateCoverage = createSelector(
  [(state) => getRentalPriceSummary(state), (state) => getContractDetails(state)],
  (priceSummary, contractDetails) => {
    const { bill_to_subtotals } = priceSummary;
    const showCorpEstimatedTotal =
      bill_to_subtotals?.length > 0 && +(bill_to_subtotals[0]?.total_currency?.amount ?? 0) !== 0;
    const showCorpAccountInfo = !!contractDetails && !contractDetails.hide_contract_name;

    return showCorpAccountInfo && showCorpEstimatedTotal;
  }
);

/**
 *  Return an array with the total breakdown items
 *  @param {object} state - Redux state
 *  @return {object[]}
 */
export const selectTotalSummaryList = createSelector(
  [
    (state) => getRentalPriceSummary(state),
    (state) => getContractDetails(state),
    (state) => getShowCorporateCoverage(state),
  ],
  (priceSummary, contractDetails, showCorporateCoverage) => {
    const { difference_in_renter_cost_from_retrieve_ticket } = priceSummary;

    const amounts = {
      billToAccounts: priceSummary?.bill_to_subtotals,
      subtotalViewAmount: priceSummary?.renter_subtotal_view,
    };

    // Split bill is only meant for insurance replacement and confirm/modify retail flow
    const isSplitBill = !!priceSummary?.bill_to_subtotals;

    const modificationBreakdown = getModificationRentalBreakdown(priceSummary, showCorporateCoverage);

    const adjustmentBreakdown = isSplitBill && getAdjustmentsSectionBreakdown(amounts, contractDetails);

    const totalBreakdownAmount = difference_in_renter_cost_from_retrieve_ticket;

    return {
      modificationBreakdown,
      adjustmentBreakdown,
      totalBreakdownAmount,
    };
  }
);
