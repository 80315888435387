import RESERVATIONS from 'constants/reservations';
import { i18n } from 'utils/i18n';

export const getFormattedPrice = (priceObject = {}, options = {}) => {
  const { asterisk = false, doubleAsterisk = false, removeMinus = false, status = '' } = options;
  const includedStatusPrice = [RESERVATIONS.EXTRAS_INCLUDED_STATUS, RESERVATIONS.EXTRAS_HIDDEN_STATUS];

  if (includedStatusPrice.includes(status)) {
    return i18n('extras_status_INCLUDED');
  }

  return `${removeMinus ? priceObject?.format.replace('-', '') : priceObject?.format}${asterisk ? '*' : ''}${
    doubleAsterisk ? '**' : ''
  }`;
};

/**
 * @name getFormattedPriceRounded
 * @description Returns price amount rounded to nearest number.
 * @param {object} priceObject - price object data
 * @returns {string}
 */
export const getFormattedPriceRounded = (priceObject = {}) => {
  const roundedNumber = Math.round(priceObject.amount);
  if (isNaN(roundedNumber)) {
    return getFormattedPrice(priceObject);
  }
  return `${priceObject.symbol} ${roundedNumber}`;
};

/**
 * @name getFormattedZeroPrice
 * @description Returns a zero value (e.g. '$ 0.00') based on a given price object, using symbol and decimal_separator to respect the currency formatting.
 * @example
 * const priceObject = { code: "USD", symbol: "$", amount: "123.56", format: "$ 123.56", formatted_amount: "123.56", decimal_separator: "."}
 * const zeroPrice = getFormattedZeroPrice(priceObject) // "$ 0.00"
 * @param {object} priceObject - given price object from a service response to be used as reference
 * @param {string} priceObject.decimal_separator
 * @param {string} priceObject.symbol
 * @returns {string}
 */
export const getFormattedZeroPrice = ({ decimal_separator, symbol }) =>
  symbol && decimal_separator ? `${symbol} 0${decimal_separator}00` : '';

export const getVehicleRateSavingsArray = (vehicleRateObject) =>
  vehicleRateObject?.price_summary?.payment_line_items?.SAVINGS;

/**
 * formatTimeAndDistance - Get time and distance label formatted (e.g. Time & Distance 2 Day(s) at $ 125.00 / day)
 * @param {object} props.chargedRate - Object with the charged rate details
 * @param {string} props.options - Set options to getFormattedPrice method
 * @return {string} formatted time and distance label
 */
export const formatTimeAndDistance = (chargedRate, options = {}) => {
  const { description, rate_type, rate_amount_view, rate_quantity } = chargedRate;
  const key = `rental_details_time_distance_${rate_type}`;
  const label = i18n(key, [rate_quantity, getFormattedPrice(rate_amount_view, options)]);
  return label !== key ? label : description;
};

/**
 * @name isPriceDifferenceRefund
 * @description Util to check if unpaid_refund object price from GBO is refund or not.
 * @example
 * const priceDifferenceObject = { code: "USD", symbol: "$", amount: "-123.56", format: "$ -123.56", formatted_amount: "-123.56", decimal_separator: "."}
 * const zeroPrice = getFormattedZeroPrice(priceDifferenceObject) // true
 * @param {object} priceDifferenceObject - given price unpaid/refund difference object from a service response
 * @param {string} priceObject.amount
 * @returns {boolean}
 */
export const isPriceDifferenceRefund = (priceDifferenceObject) => /^-/.test(priceDifferenceObject?.amount);

export const getPaymentLines = (paymentLineItems, mileageInfo, estimatedTotalView, payer) => {
  if (!paymentLineItems) return false;

  const byRenterPayer = (item) => item.payer === payer || !item.payer;

  const {
    VEHICLE_RATE = [],
    COVERAGE = [],
    FEE = [],
    TAX = [],
    EQUIPMENT = [],
    MISCELLANEOUS = [],
    EPLUS_REDEMPTION_SAVINGS = [],
    SAVINGS = [],
  } = paymentLineItems;

  const taxesAndFeeLineItems = [...TAX, ...FEE];
  const extrasLineItems = [...EQUIPMENT, ...MISCELLANEOUS];
  const savingsLineItems =
    payer === 'BILLTO' && estimatedTotalView.amount === '0.00' ? [] : [...EPLUS_REDEMPTION_SAVINGS, ...SAVINGS];

  return {
    taxesAndFeeLineItems: taxesAndFeeLineItems.filter(byRenterPayer),
    extrasLineItems: extrasLineItems.filter(byRenterPayer),
    savingsLineItems: savingsLineItems.filter(byRenterPayer),
    vehicleLineItems: VEHICLE_RATE.filter(byRenterPayer),
    protectionsLineItems: COVERAGE.filter(byRenterPayer),
    ...(!!mileageInfo && { mileageInfo }),
    ...(!!estimatedTotalView && { estimatedTotalView }),
  };
};
