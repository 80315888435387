import { gboReservationPath } from './session';
import { gmaReservationPath } from './reservationLocation';

export const paymentsPath = [...gboReservationPath, 'payments'];
export const cancelRebookPath = [...gboReservationPath, 'cancel_rebook'];
export const selectedPaymentMethodPath = [...gboReservationPath, 'selected_payment_method'];
export const selectedPaymentTypePath = [...selectedPaymentMethodPath, 'payment_type'];
export const cardSubmissionPath = [...gmaReservationPath, 'card_submission_session'];
export const cardSubmissionUrlPath = [...cardSubmissionPath, 'card_submission_url'];
export const sessionIdPath = [...cardSubmissionPath, 'session_id'];
