/**
 * Map of all Modal Keys
 * uniqueness of modal keys is ensured by eslint "no-dupe-keys"
 * MODAL_KEYS should be a flat object otherwise eslint rule won't work
 */

const MODAL_KEYS = {
  GMI_RES_FLOW: 'GMI_RES_FLOW',
  INVALID_DATE_FOR_RES_FLOW: 'INVALID_DATE_FOR_RES_FLOW',
  VAN_SELECTED_12_AND_15_TERMS_USE: 'VAN_SELECTED_12_AND_15_TERMS_USE',
  CONVERTED_PRICES_MODAL: 'CONVERTED_PRICES_MODAL',
  CURRENCY_CONVERSION_DETAILS_MODAL: 'CURRENCY_CONVERSION_DETAILS_MODAL',
  TAXES_FEES_MODAL: 'TAXES_FEES_MODAL',
  BUNDLES_DETAILS_MODAL: 'BUNDLES_DETAILS_MODAL',
  PRE_RATE_ADDITIONAL_INFO_MODAL: 'PRE_RATE_ADDITIONAL_INFO_MODAL',
  LEARN_ABOUT_PREPAY_AND_PAY_LATER: 'LEARN_ABOUT_PREPAY_AND_PAY_LATER',
  PREPAY_TERMS_CONDITIONS_MODAL: 'PREPAY_TERMS_CONDITIONS_MODAL',
  DEALS_EMAIL_SPECIALS_MODAL: 'DEALS_EMAIL_SPECIALS_MODAL',
  PREPAY_EDIT_MODAL: 'EDIT_PAYMENT_MODAL',
  EXTRAS_EXCLUSION_MODAL: 'EXTRAS_EXCLUSION_MODAL',
  RENTAL_DETAILS_TAXES_FEES_MODAL: 'RENTAL_DETAILS_TAXES_FEES_MODAL',
  RENTAL_DETAILS_LEARN_MORE_MODAL: 'RENTAL_DETAILS_LEARN_MORE_MODAL',
  RENTAL_DETAILS_AFTER_HOURS_MODAL: 'RENTAL_DETAILS_AFTER_HOURS_MODAL',
  RENTAL_POLICIES: 'RENTAL_POLICIES',
  KEY_RENTAL_FACTS_MAIN: 'KEY_RENTAL_FACTS_MAIN',
  KEY_RENTAL_FACTS: 'KEY_RENTAL_FACTS',
  KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS: 'KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS',
  KEY_RENTAL_FACTS_RENTAL_POLICIES: 'KEY_RENTAL_FACTS_RENTAL_POLICIES',
  FINDING_RESERVATION_TIPS_MODAL: 'FIND_RESERVATION_TIPS_MODAL',
  CREDIT_CARD_DATE_EXPIRATION_MODAL: 'CREDIT_CARD_DATE_EXPIRATION',
  CONFIRM_CANCEL_RESERVATION_MODAL: 'CANCEL_RESERVATION',
  EDIT_VEHICLE: 'EDIT_VEHICLE',
  MODIFY_RESERVATION: 'MODIFY_RESERVATION',
  REMOVE_CID_MODAL: 'REMOVE_CID_MODAL',
  CID_TERMS_AND_CONDITIONS_MODAL: 'CID_TERMS_AND_CONDITIONS_MODAL',
  REMOVE_PARTNER_REWARDS_MODAL: 'REMOVE_PARTNER_REWARDS_MODAL',
  CONFIRM_DISCARD_RESERVATION_MODAL: 'CONFIRM_DISCARD_RESERVATION_MODAL',
  DISCARD_RESERVATION: 'DISCARD_RESERVATION',
  LIMITED_INVENTORY_MODAL: 'LIMITED_INVENTORY_MODAL',
  LIMITED_INVENTORY_VEHICLE_MODAL: 'LIMITED_INVENTORY_VEHICLE_MODAL',
  FREQUENT_TRAVELER_RECOVERY_FEE_MODAL: 'FREQUENT_TRAVELER_RECOVERY_FEE_MODAL',
  FREQUENT_TRAVELER_SURCHARGE_MODAL: 'FREQUENT_TRAVELER_SURCHARGE_MODAL',
  PRP_AUTH_CONFLICT_MODAL: 'CHANGE_PARTNER_REWARDS',
  SESSION_TIMEOUT_MODAL: 'SESSION_TIMEOUT',
  PRP_AUTH_ADD_PROGRAM_MODAL: 'PRP_AUTH_ADD_PROGRAM_MODAL',
  REQUIRED_MODAL_POLICY: 'REQUIRED_MODAL_POLICY',
  GLOBAL_GATEWAY: 'GLOBAL_GATEWAY',
  LOYALTY_TERMS_AND_CONDITIONS: 'ALAMO_INSIDER_TERMS_AND_CONDITIONS',
  RECOMMENDED_EXTRA_DETAILS_MODAL: 'THIRD_PARTY_LIABILITY_MODAL',
  RECOMMENDED_EXTRAS_ALERT_MODAL: 'REGION_MANDATED_COVERAGE_MODAL',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT_INFORMATION',
  EDIT_PROFILE_PRP_MODAL: 'ADD_PARTNER_REWARDS',
  GUARANTEED_RES: 'GUARANTEED_VEHICLE_SELECTION_MODAL',
  GUARANTEED_RES_IFRAME: 'GUARANTEED_RES_IFRAME',
  EDIT_DRIVERS_LICENSE_MODAL: 'ADD_DRIVER_LICENSE_INFORMATION',
  EDIT_PROFILE_CONTACT_INFORMATION_MODAL: 'ADD_CONTACT_INFORMATION',
  EDIT_PROFILE_WIZARD_MODAL: 'EDIT_PROFILE_WIZARD_MODAL',
  EXTEND_RENTAL_INFORMATION_MODAL: 'EXTEND_RENTAL',
  MISSING_TRIPS_MODAL: 'MISSING_TRIPS',
  AFTER_HOURS_RETURN_INSTRUCTIONS_MODAL: 'AFTER_HOURS_RETURN_INSTRUCTIONS',
  REMOVE_COUPON_MODAL: 'REMOVE_COUPON_MODAL',
  REMOVE_PRODUCT_CODE_MODAL: 'REMOVE_PRODUCT_CODE_MODAL',
  LEARN_MORE_ABOUT_SKIP_THE_COUNTER_MODAL: 'LEARN_MORE_ABOUT_SKIP_THE_COUNTER',
  LEARN_MORE_ABOUT_SKIP_THE_COUNTER_ELIGIBLE_MODAL: 'LEARN_MORE_ABOUT_SKIP_THE_COUNTER_ELIGIBLE',
  LEARN_MORE_ABOUT_SKIP_THE_COUNTER_INELIGIBLE_MODAL: 'LEARN_MORE_ABOUT_SKIP_THE_COUNTER_INELIGIBLE',
  LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_INACTIVE_MODAL: 'ACCELERATE_CHECK-IN',
  LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_ACTIVATED_MODAL: 'ABOUT_CHECK_IN_MODAL',
  LEARN_MORE_ACTIVATED_ACCELERATED_CHECKIN_MODAL: 'LEARN_MORE_ACTIVATED_ACCELERATED_CHECKIN_MODAL',
  RETURN_DIRECTIONS_MODAL: 'RETURN_DIRECTIONS',
  RESFLOW_LOGOUT_MODAL: 'RESFLOW_LOGOUT_MODAL',
  LEARN_MORE_ABOUT_ROADSIDE_ASSISTANCE_MODAL: 'ROADSIDE ASSISTANCE',
  ADDITIONAL_DRIVER_ADD_MODAL: 'ADD_ADDITIONAL_DRIVER_MODAL',
  ADDITONAL_DRIVER_REMOVE_MODAL: 'ADDITONAL_DRIVER_REMOVE_MODAL',
  ADDITIONAL_DRIVER_DETAILS_MODAL: 'ADD_DRIVER_INFO_MODAL',
  ADDITIONAL_DRIVER_OCI_REQUEST_MODAL: 'ADDITIONAL_DRIVER_OCI_REQUEST_MODAL',
  LEARN_MORE_ABOUT_ADDITIONAL_DRIVER: 'ABOUT_ADDITIONAL_DRIVER_MODAL',
  DRIVER_DETAILS_MISSING_OCI_REQUEST_MODAL: 'DRIVER_DETAILS_MISSING_OCI_REQUEST_MODAL',
  DO_NOT_RENT_MODAL: 'DO_NOT_RENT_MODAL',
  CHECK_IN_ALREADY_ACTIVATED_MODAL: 'CHECK_IN_ALREADY_ACTIVATED_MODAL',
  SIGN_IN_MODAL: 'SIGN_IN_MODAL',
  IN_RES_OCI_WIZARD_MODAL: 'IN_RES_OCI_WIZARD_MODAL',
  IN_RES_OCI_EDIT_PROFILE_MODAL: 'CHECK_IN_EDIT_INFORMATION_MODAL',
  IN_RES_OCI_PARTIAL_PROFILE_MODAL: 'CHECK_IN_SIGN_IN_MODAL',
  STC_ALREADY_ACTIVATED_MODAL: 'ALREADY_SKIPPING_THE_COUNTER_MODAL',
  STC_INELIGIBLE_MODAL: 'RESERVATION_INELIGIBLE_MODAL',
  STC_NOT_CHECKED_IN_MODAL: 'STC_NOT_CHECKED_IN_MODAL',
  ALREADY_SKIPPING_THE_COUNTER_HELP_MODAL: 'SKIP_THE_COUNTER_HELP_MODAL',
  ALREADY_CHECKED_IN_HELP_MODAL: 'ALREADY_CHECKED_IN_HELP',
  PRICE_BREAKDOWN_MODAL: 'PRICE_BREAKDOWN_MODAL',
  STC_PROTECTION_OPTION_DETAILS_MODAL: 'STC_PROTECTION_OPTION_DETAILS',
  EXIT_CHECK_IN_FLOW_MODAL: 'CHECK_IN_EXIT_MODAL',
  STC_ADD_CREDIT_CARD_MODAL: 'STC_ADD_CREDIT_CARD_MODAL',
  STC_VIEW_DIRECTIONS_LOT_MODAL: 'STC_VIEW_DIRECTIONS_LOT_MODAL',
  STC_VIEW_TICKET_MODAL: 'STC_VIEW_TICKET_MODAL',
  CHECKIN_LOYALTY_CONFLICT_MODAL: 'CHECKIN_LOYALTY_CONFLICT',
  CHECKIN_FIRST_NAME_CONFLICT_MODAL: 'CHECKIN_FIRST_NAME_CONFLICT_MODAL',
  EU_YOUNG_DRIVER_FEE_MODAL: 'YOUNG_DRIVER_FEE_MODAL',
  STC_ELIGIBILITY_LOSS: 'UNABLE_TO_SKIP_COUNTER',
  BOOK_WITH_PARTNER: 'BOOK_WITH_PARTNER',
  LOCAL_ADDENDUM_MODAL: 'LOCAL_ADDENDUM_MODAL',
  COUPON_DOES_NOT_APPLY: 'COUPON_DOES_NOT_APPLY',
  ADDITIONAL_DRIVER_REQUIRED_MODAL: 'ADDITIONAL_DRIVER_REQUIRED_MODAL',
  CANCELLED_RESERVATION_MODAL: 'CANCELLED_RESERVATION_MODAL',
  SOLD_OUT_MODAL: 'SOLD_OUT_MODAL',
  VIRGIN_ATLANTIC_FLIGHT_CLUB_MODAL: 'VIRGIN_LOYALTY_INFO_MODAL',
  TRIP_ENHANCEMENTS_MODAL: 'TRIP_ENHANCEMENTS_LEARN_MORE_MODAL',
  CHECK_IN_INELIGIBLE_MODAL: 'RESERVATION_OCI_INELIGIBLE_MODAL',
  PARTIAL_PROFILE_FIRST_NAME_CONFLICT_MODAL: 'PARTIAL_PROFILE_FIRST_NAME_CONFLICT_MODAL',
  NO_LMS_AVAILABLE_MODAL: 'NO_LMS_AVAILABLE_MODAL',
  NO_PAS_AVAILABLE_MODAL: 'NO_PAS_AVAILABLE_MODAL',
  VEHICLE_CLASS_FILTER_MODAL: 'FILTER_BY_VEHICLE_CLASS',
  CID_CONFLICT_MODAL: 'CID_CONFLICT_MODAL',
  LOCATION_TYPE_FILTER_MODAL: 'FILTER_BY_LOCATION_TYPE',
  FLIGHT_DELAYED_MODAL: 'FLIGHT_DELAYED_MODAL',
  UPDATE_FLIGHT_INFO_MODAL: 'UPDATE_FLIGHT_INFORMATION',
  FLIGHT_CONFIRMED_MODAL: 'FLIGHT_INFO_CONFIRMED',
  CANCEL_UPDATES_MODAL: 'CANCEL_UPDATES_MODAL',
  EMAIL_ALREADY_IN_USE_MODAL: 'DUPLICATE_EMAIL',
  CHANGE_RETURN_LOCATION_MODAL: 'CHANGE RETURN LOCATION DETAILS MODAL',
  RENTAL_NOT_ELIGIBLE_FOR_MODIFICATION_MODAL: 'NOT ELIGIBLE FOR MODIFY',
  UPDATE_EMAIL_ADDRESS_MODAL: 'PLEASE_UPDATE_YOUR_EMAIL_ADDRESS',
  EMAIL_UPDATE_SUCCESS: 'EMAIL_UPDATE_SUCCESS',
  NO_CURRENT_RENTAL: 'NO_CURRENT_RENTAL',
  CANADA_FRENCH_REDIRECTION_MODAL: 'AVIS_DE_REDIRECTION',
};

export const MODAL_THEMES = {
  BLUE: 'BLUE',
  WHITE: 'WHITE',
};

export const MODAL_BUTTON_TYPES = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
  LINK: 'LINK',
  SKIP: 'SKIP',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
};

export default MODAL_KEYS;
